import { TimeLeft } from 'core/hooks/useTimeLeft'
import {
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
	differenceInSeconds,
	subDays,
	subHours,
	subMinutes,
} from 'date-fns'

export function getTimeDifference(date: Date, from = new Date()) {
	const days = differenceInDays(date, from)
	const hours = differenceInHours(subDays(date, days), from)
	const minutes = differenceInMinutes(
		subDays(subHours(date, hours), days),
		from
	)
	const seconds = differenceInSeconds(
		subMinutes(subDays(subHours(date, hours), days), minutes),
		from
	)
	const isRightNow = differenceInSeconds(date, from) === 0
	const isLastDay = days === 0 && hours >= 0
	const isLastHour = days === 0 && hours === 0 && minutes >= 0
	const isLastMinute =
		days === 0 && hours === 0 && minutes === 0 && seconds >= 0
	const isOverdue =
		!isRightNow && days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0
	return {
		days,
		hours,
		minutes,
		seconds,
		isLastDay,
		isLastHour,
		isLastMinute,
		isRightNow,
		isOverdue,
	}
}

type TimeSeverity = 'normal' | 'urgent' | 'overdue'

export function getTimeSeverity({
	minutes,
	isOverdue,
	isLastHour,
}: TimeLeft): TimeSeverity {
	if (isOverdue) return 'overdue'
	if (isLastHour && minutes < 30) return 'urgent'
	return 'normal'
}

export const getFormattedHoursRequired = (hoursRequired?: number | null) => {
	if (!hoursRequired) return { value: null, unit: null }

	if (hoursRequired === 0.5) {
		return { value: 30, unit: 'm' }
	}

	if (hoursRequired === 1) {
		return { value: 1, unit: 'h' }
	}

	return { value: hoursRequired, unit: '+h' }
}
