// -- Sentry initialization should be imported first!
import './instrument'
// --

import { reactErrorHandler } from '@sentry/react'
import App from 'App'
import { AnalyticsProvider } from 'analytics/provider'
import AuthenticatedOnly from 'auth/AuthenticatedOnly'
import { AuthenticationProvider } from 'auth/AuthenticationProvider'
import { SignOutPage, SignedOutPage } from 'auth/Logout'
import DistributeWithToken from 'bookbuilds/pages/distribute-with-token'
import Spinner from 'core/components/Spinner'
import { registerErrorReporter } from 'core/utils/errorReporter'
import { GraphQLProvider } from 'graphql/client'
import { PriceDiscoveryRespondWithToken } from 'interests/price-discovery/RespondWithToken'
import { NotificationsProvider } from 'notifications/Notifications'
import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route } from 'react-router'
import './index.css'
import { InstrumentedRoutes } from './instrument'

// Register the GCP error reporter
registerErrorReporter()

const container = document.getElementById('root') as HTMLElement

const root = createRoot(container, {
	// Callback called when React automatically recovers from errors.
	onRecoverableError: reactErrorHandler(),

	// TODO: Add more error handling callbacks when we move to React19
	// https://docs.sentry.io/platforms/javascript/guides/react/#react-19-error-reporting
	//
	// Callback called when an error is thrown and not caught by an ErrorBoundary.
	// onUncaughtError: reactErrorHandler((error, errorInfo) => {
	// 	console.warn('Uncaught error', error, errorInfo.componentStack)
	// }),
	//
	// // Callback called when React catches an error in an ErrorBoundary.
	// onCaughtError: reactErrorHandler(),
})

root.render(
	<AuthenticationProvider>
		<BrowserRouter>
			<Suspense fallback={<Spinner />}>
				<InstrumentedRoutes>
					{/* Public */}
					<Route
						path="/bookbuilds/distribute"
						element={<DistributeWithToken />}
					/>

					<Route
						path="/interests/price-discovery/respond"
						element={<PriceDiscoveryRespondWithToken />}
					/>

					<Route path="/auth/signout" element={<SignOutPage />} />

					<Route path="/auth/signedout" element={<SignedOutPage />} />

					{/* Private */}
					<Route
						path="/*"
						element={
							<AuthenticatedOnly>
								<GraphQLProvider>
									<AnalyticsProvider>
										<NotificationsProvider>
											<App />
										</NotificationsProvider>
									</AnalyticsProvider>
								</GraphQLProvider>
							</AuthenticatedOnly>
						}
					/>
				</InstrumentedRoutes>
			</Suspense>
		</BrowserRouter>
	</AuthenticationProvider>
)
