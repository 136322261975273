import clsx from 'clsx'
import Button from 'core/components/Button'
import ErrorMessage from 'core/components/ErrorMessage'
import { Bell } from 'core/components/Icon'
import { SelectList, useSelect } from 'core/components/Select'
import Spinner from 'core/components/Spinner'
import { useQueryPromise } from 'core/hooks/useQueryPromise'
import { AnimatePresence, motion } from 'framer-motion'
import {
	NotificationFragment,
	useMarkAllNotificationsAsReadMutation,
	useMarkNotificationsAsReadMutation,
} from 'graphql/generated'
import Notification from 'notifications/Notification'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useNotificationsContext } from './Notifications'

type Props = {
	className?: string
}

export const WIDTH = 300

const PAGE_SIZE = 30

function NotificationsList(props: Readonly<Props>) {
	const navigate = useNavigate()

	const context = useNotificationsContext()
	const query = useMemo(() => context.response[0], [context.response])
	const refetch = useMemo(() => context.response[1], [context.response])
	const refetchAsync = useQueryPromise(query.operation)

	const [, markNotificationsAsRead] = useMarkNotificationsAsReadMutation()
	const [, markAllNotificationsAsRead] =
		useMarkAllNotificationsAsReadMutation()

	const [isMarkingAllAsRead, setMarkingAllAsRead] = useState(false)

	const notifications = useMemo(
		() => query.data?.notifications.items || [],
		[query.data?.notifications.items]
	)

	const total = useMemo(
		() => query.data?.notifications.total || 0,
		[query.data?.notifications.total]
	)

	const totalUnread = useMemo(
		() => query.data?.notifications.totalUnread || 0,
		[query.data?.notifications.totalUnread]
	)

	const {
		isOpen,
		getToggleButtonProps,
		getMenuProps,
		highlightedIndex,
		getItemProps,
		closeMenu,
	} = useSelect<NotificationFragment>({
		items: notifications,
		selectedItem: undefined,
		onSelectedItemChange: (item) =>
			item.selectedItem && handleNotificationClick(item.selectedItem),
	})

	function handleNotificationClick(notification?: NotificationFragment) {
		if (!notification?.url) return
		navigate(notification.url)
		markNotificationsAsRead({ ids: [notification.id] })
		refetch()
		closeMenu()
	}

	async function handleMarkAllAsReadClick() {
		setMarkingAllAsRead(true)
		await markAllNotificationsAsRead({})
		await refetchAsync()
		setMarkingAllAsRead(false)
		closeMenu()
	}

	return (
		<div
			className={clsx(
				'relative flex items-center',
				isOpen ? 'z-3' : 'z-2'
			)}
		>
			<Button
				{...props}
				{...getToggleButtonProps()}
				role="status"
				aria-atomic
				aria-labelledby={undefined}
				aria-label={`${totalUnread} unread ${
					totalUnread === 1 ? 'notification' : 'notifications'
				}`}
				color={isOpen ? 'white' : 'gray'}
				variant="bare"
				size="small"
				className={clsx('!gap-0', props.className)}
			>
				{query.fetching && !query.data ? (
					<Spinner
						className="h-4 w-4 xs:h-4.5 xs:w-4.5"
						color="gray"
					/>
				) : (
					<Bell
						className={clsx(
							'h-4 w-4 xs:h-4.5 xs:w-4.5',
							!!totalUnread && 'text-magenta'
						)}
					/>
				)}
				<AnimatePresence>
					{!!totalUnread && (
						<motion.div
							initial={{ opacity: 0, width: 0, scale: 0 }}
							animate={{ opacity: 1, width: 'auto', scale: 1 }}
							exit={{ opacity: 0, width: 0, scale: 0 }}
							className="flex items-center justify-center overflow-hidden"
						>
							<span className="ml-1 rounded-xl bg-magenta px-1.5 pb-1 pt-[3px] text-xs font-bold leading-none text-white">
								{totalUnread}
							</span>
						</motion.div>
					)}
				</AnimatePresence>
			</Button>

			<SelectList
				{...getMenuProps()}
				className="!-right-5 !left-auto !max-h-[80vh] !p-0"
				style={{ width: WIDTH }}
				animate={isOpen ? 'open' : 'closed'}
			>
				<li className="sticky top-0 flex items-baseline justify-between bg-blue-dark bg-opacity-50 px-5 py-2 font-bold leading-5 text-white backdrop-blur-sm">
					Notifications
					{!!notifications.length && (
						<Button
							variant="bare"
							size="tiny"
							color="gray"
							className="h-4"
							loading={isMarkingAllAsRead}
							onMouseDown={() => handleMarkAllAsReadClick()}
						>
							Mark all as read
						</Button>
					)}
				</li>

				{query.fetching && !query.data ? (
					<Spinner size={18} className="my-3" />
				) : query.error ? (
					<ErrorMessage />
				) : !notifications.length ? (
					<li className="px-5 py-3 text-sm text-gray">
						No notifications yet
					</li>
				) : (
					notifications.map((notification, index) => (
						<li key={notification.id}>
							<Notification
								createdAt={notification.createdAt}
								readAt={notification.readAt}
								title={notification.title}
								description={notification.description}
								highlighted={highlightedIndex === index}
								{...getItemProps({
									item: notification,
									index,
								})}
							/>
						</li>
					))
				)}

				{!!query.data && total > context.limit && (
					<li className="px-5 py-3">
						<Button
							variant="bare"
							className="w-full"
							onClick={() =>
								context.setLimit((currentLimit) =>
									Math.min(currentLimit + PAGE_SIZE, total)
								)
							}
						>
							More
						</Button>
					</li>
				)}
			</SelectList>
		</div>
	)
}

export default NotificationsList
