import { clsx } from 'clsx'
import OrgCategoryBadge from 'core/components/Badge/OrgCategoryBadge'
import { PlusCircle, Slash } from 'core/components/Icon'
import TickCircleIcon from 'core/components/Icon/TickCircleIcon'
import Tooltip from 'core/components/Tooltip'
import { ellipsis } from 'core/styles/text'
import { getHolderHasRecentChange } from 'core/utils/holdings'
import { OrgGroupItem } from './ManualSection'
import RecentChangeBadge from './RecentChangeBadge'

type Props = {
	org: OrgGroupItem
	onHandleManualFirmSelection: (orgId: string, checked: boolean) => void
}

const ManualOrg = ({ org, onHandleManualFirmSelection }: Props) => {
	const { reportDate, valueChangedADVPercent } = org
	const { hasRecentChange, daysSinceLastReport } = getHolderHasRecentChange({
		reportDate,
		valueChangedADVPercent,
	})

	return (
		<label
			className={clsx(
				'group mb-1 flex h-7.5 w-full cursor-pointer items-center justify-between rounded-bl-2xl rounded-br rounded-tl-2xl rounded-tr p-1 transition-all last:mb-0',
				org.excluded
					? 'cursor-not-allowed disabled:hover:bg-blue-darker'
					: org.checked
						? 'bg-[#17428E] bg-opacity-50 hover:bg-[#17428E] hover:bg-opacity-80 active:bg-[#17428E] active:bg-opacity-30'
						: 'bg-blue-darker hover:bg-[#17428E] hover:bg-opacity-20 active:bg-[#17428E] active:bg-opacity-10'
			)}
		>
			<div className="flex items-center whitespace-nowrap">
				<input
					type="checkbox"
					checked={org.checked}
					disabled={org.excluded}
					className="peer absolute h-0 w-0 opacity-0"
					aria-labelledby={org.id + '-label'}
					onChange={(evt) => {
						onHandleManualFirmSelection(org.id, evt.target.checked)
					}}
				/>

				{org.excluded ? (
					<Slash
						size={22}
						className="flex-shrink-0 cursor-not-allowed stroke-gray-dark stroke-2 opacity-20 transition-all animate-icon-lines-mid"
					/>
				) : org.checked ? (
					<TickCircleIcon className="h-[22px] w-[22px] flex-shrink-0 stroke-yellow stroke-2 transition-all animate-icon-lines-mid group-hover:opacity-90" />
				) : (
					<PlusCircle
						size={22}
						className="flex-shrink-0 stroke-gray-dark stroke-2 transition-all animate-icon-lines-mid group-hover:stroke-gray-light group-active:stroke-gray"
					/>
				)}

				<Tooltip
					content={
						org.excluded
							? 'This firm is in your Excluded Firms list'
							: org.name
					}
					side="top"
					align="center"
					delayDuration={100}
					className={clsx(
						'text-xs leading-snug',
						!org.excluded && 'max-w-[200px] text-center'
					)}
				>
					<div className="flex w-full">
						<span
							id={org.id + '-label'}
							className={clsx(
								'font-normaltransition-all ml-1.5 text-sm',
								org.excluded
									? 'font-normal text-gray-dark'
									: 'text-white',
								ellipsis,
								org.checked && 'font-bold',
								hasRecentChange
									? '!max-w-[180px] md:!max-w-[200px] lg:!max-w-[160px] min-[1150px]:!max-w-[120px] xl:!max-w-[215px]'
									: '!max-w-[200px] md:!max-w-[380px] lg:!max-w-[200px] min-[1150px]:!max-w-[260px] xl:!max-w-[350px]'
							)}
						>
							{org.name}{' '}
						</span>
					</div>
				</Tooltip>

				{hasRecentChange && (
					<RecentChangeBadge
						notionalChangedUSD={org.valueChangedUSD}
						trend={org.trend}
						daysSinceLastReport={daysSinceLastReport}
						className={clsx(
							org.excluded
								? 'opacity-40'
								: 'duration-400 transition-all group-hover:bg-opacity-80 group-active:scale-[0.97] group-active:bg-opacity-30'
						)}
					/>
				)}
			</div>
			<div
				className={clsx(
					'flex min-w-[27px] justify-end',
					org.excluded && 'opacity-40'
				)}
			>
				<OrgCategoryBadge
					category={org.category}
					highlighted={org.checked}
					className={clsx(
						org.checked && !org.excluded && 'bg-blue-highlight',
						!org.excluded &&
							'group-hover:bg-opacity-80 group-active:scale-95 group-active:bg-opacity-30'
					)}
				/>
			</div>
		</label>
	)
}

export default ManualOrg
