import * as Sentry from '@sentry/react'
import { API_ENDPOINT } from 'core/utils/api'
import { ENABLED } from 'core/utils/errorReporter'
import { VERSION } from 'core/utils/version'
import { useEffect } from 'react'
import {
	createRoutesFromChildren,
	matchRoutes,
	Routes,
	useLocation,
	useNavigationType,
} from 'react-router'

type Environment =
	| 'production'
	| 'mirror'
	| 'uat'
	| 'dev'
	| 'local'
	| 'dynamic-env'
	| 'other'

const environment: Environment = API_ENDPOINT.startsWith('https://api.appital')
	? 'production'
	: API_ENDPOINT.startsWith('https://api.m.appital')
		? 'mirror'
		: API_ENDPOINT.startsWith('https://api.uat.appital')
			? 'uat'
			: API_ENDPOINT.startsWith('https://api.dev.appital')
				? 'dev'
				: API_ENDPOINT.startsWith('http://localhost')
					? 'local'
					: API_ENDPOINT.includes('api.dynenv.dev.appital.io')
						? 'dynamic-env'
						: 'other'

Sentry.init({
	enabled: !!ENABLED,

	// Note: this needs to be reflected in the CSP
	dsn: 'https://f0f7e32e8c9bd2e0c0525cb922e135e7@o4507272330280960.ingest.de.sentry.io/4508200865169488',

	// Tunnel
	tunnel: API_ENDPOINT + '/sentry',

	// Set the environment
	environment,

	// Set the release version
	release: VERSION,

	integrations: [
		// See docs for support of different versions of variation of react router
		// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.replayIntegration(),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	tracesSampleRate: environment === 'production' ? 1.0 : 0,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/api\.\w*?.?appital\.io/],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: environment === 'production' ? 0.1 : 0,
	replaysOnErrorSampleRate: environment === 'production' ? 1.0 : 0,

	// Profiling
	profilesSampleRate: environment === 'production' ? 1.0 : 0,
})

// Integrate Sentry with React Router's Routes component
export const InstrumentedRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
