import { init, trackPageView } from 'analytics'
import { useAuthToken } from 'auth/useAuthToken'
import { usePolling } from 'core/hooks/usePolling'
import { useMeQuery } from 'graphql/generated'
import { ReactNode, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

/**
 * Initialises the analytics client with the logged-in user and provides
 * automatic pageview tracking when user navigates via react-router.
 *
 * **Must be a child of `<Router />` and `<GraphQLProvider />`**
 */
export function AnalyticsProvider({ children }: { children?: ReactNode }) {
	const location = useLocation()
	const [{ data }, refetch] = useMeQuery()
	const getToken = useAuthToken()

	// Poll every 30s to keep session ID updated
	usePolling({ refetch, interval: 30000 })

	// Track page changes
	const [page, setPage] = useState(
		window.location.pathname + window.location.search + window.location.hash
	)

	// Ensure we only proceed with tracking if it's enabled/initialised first
	const [initialised, setInitialised] = useState(false)

	// Initialise the analytics client when we have a logged-in user
	useEffect(() => {
		if (!data) return

		const user = data.me
		const org = user.organization

		const success = init({
			userId: user.id,
			sessionId: user.sessionId,
			userProperties: {
				'Org ID': org.id,
				'Org Name': org.name,
				'Org Type': org.type,
				Roles: user.roles.map((r) => r.id),
				'Auth0 ID': user.auth0Id || undefined,
				'Screen Width': window.innerWidth,
				'Screen Height': window.innerHeight,
			},
			getToken,
		})

		setInitialised(success)
	}, [data, getToken])

	// Track history changes (navigation) via the page state
	useEffect(() => {
		if (!initialised) return

		// This should ensure a unique page view if the location pathname,
		// search or hash changes
		setPage(location.pathname + location.search + location.hash)
	}, [location, initialised])

	// Whenever the page changes, track as a pageview
	useEffect(() => {
		// Skip if not initialised yet
		if (!initialised || !page) return

		// Track pageview
		trackPageView()
	}, [page, initialised])

	return <>{children}</>
}
