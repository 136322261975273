import { setUser, withProfiler } from '@sentry/react'
import InactivityLogout from 'auth/InactivityLogout'
import Footer from 'core/components/Footer'
import Nav from 'core/components/Nav'
import Spinner from 'core/components/Spinner'
import { useUser } from 'core/hooks/useUser'
import { useUserRoles } from 'core/hooks/useUserRoles'
import PageNotFound from 'core/pages/NotFound'
import SettingUp from 'core/pages/SettingUp'
import UserError from 'core/pages/UserError'
import { setErrorUserId } from 'core/utils/errorReporter'
import { useBookbuildsDraftsTotalQuery } from 'graphql/generated'
import PopupNotificationsList from 'notifications/PopupNotificationsList'
import { Suspense, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router'

const Opps = lazy(() => import('opportunities'))
const Bookbuilds = lazy(() => import('bookbuilds'))
const Orders = lazy(() => import('orders'))
const Interests = lazy(() => import('interests'))
const Admin = lazy(() => import('admin'))
const Management = lazy(() => import('management'))
const Users = lazy(() => import('users'))
const Legal = lazy(() => import('legal'))

function App() {
	const user = useUser()

	// user data is still loading
	if (user.fetching) {
		return <Spinner />
	}

	// user data error
	if (user.error) {
		return <UserError error={user.error} />
	}

	// set user in sentry and error reporting
	if (user.id && user.email) {
		setUser({ id: user.id, email: user.email })
		setErrorUserId(user.id)
	}

	return (
		<>
			<Nav />

			<Suspense fallback={<Spinner />}>
				<Routes>
					<Route path="/" element={<DefaultRoute />} />
					<Route path="/opportunities">
						<Route index path="*" element={<Opps />} />
					</Route>
					<Route path="/orders">
						<Route index path="*" element={<Orders />} />
					</Route>
					<Route path="/bookbuilds">
						<Route index path="*" element={<Bookbuilds />} />
					</Route>
					<Route path="/interests">
						<Route index path="*" element={<Interests />} />
					</Route>
					<Route path="/admin">
						<Route index path="*" element={<Admin />} />
					</Route>
					<Route path="/management">
						<Route index path="*" element={<Management />} />
					</Route>
					<Route path="/users">
						<Route index path="*" element={<Users />} />
					</Route>
					<Route path="/legal">
						<Route index path="*" element={<Legal />} />
					</Route>
					<Route path="*" element={<PageNotFound />} />
				</Routes>
			</Suspense>
			<Footer />
			<PopupNotificationsList />
			<InactivityLogout
				timeoutMins={user.organization?.inactivityTimeoutMins ?? 30}
			/>
		</>
	)
}

function DefaultRoute() {
	const roles = useUserRoles()

	const isAdmin =
		roles.includes('admin_editor') ||
		roles.includes('admin_viewer') ||
		roles.includes('admin_notifier')

	const isManager = roles.some((r) => r.endsWith('_manager'))

	const canViewOrEditOrders =
		roles.includes('orders_editor') || roles.includes('orders_viewer')

	const canViewOrEditBookBuilds =
		roles.includes('bookbuilds_editor') ||
		roles.includes('bookbuilds_viewer')

	const [draftsQuery] = useBookbuildsDraftsTotalQuery({
		pause: !canViewOrEditBookBuilds,
		requestPolicy: 'network-only',
	})

	// No roles defined, assume account is still being set up
	if (!roles.length) {
		return <SettingUp />
	}

	// Admin user

	if (isAdmin) {
		return <Navigate to="/admin" />
	}

	// Manager user

	if (isManager) {
		return <Navigate to="/management" />
	}

	// Normal user, might go to bookbuilds if there's at least one draft

	if (draftsQuery.fetching) {
		return <Spinner />
	}

	if (draftsQuery.data && draftsQuery.data.bookbuilds.total > 0) {
		return <Navigate to="/bookbuilds" />
	}

	if (canViewOrEditOrders) {
		return <Navigate to="/opportunities" />
	}

	if (canViewOrEditBookBuilds) {
		return <Navigate to="/bookbuilds" />
	}

	return <Navigate to="/users/me" />
}

// App instrumented with the Sentry profiler
export default withProfiler(App)
